import smoothscroll from 'smoothscroll-polyfill';

const SmoothScroll = {
    init() {
        smoothscroll.polyfill();

        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });
    }
};

export default SmoothScroll;
